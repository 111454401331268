/* eslint no-use-before-define: ["off"] camelcase: "off" */
export interface User {
  id: string;
  address: string;
  nfts: Array<Ownership>;
}

export class NFT {
  id: string;

  nftId: string;

  nftType1: string;

  nftType2: string;

  metadataUrl: string;

  createTime: string;

  owner?: Ownership | Array<Ownership>;

  constructor(
    id: string,
    nftId: string,
    nftType1: string,
    nftType2: string,
    metadataUrl: string,
    createTime: string,
    owner: Ownership,
  ) {
    this.id = id;
    this.nftId = nftId;
    this.nftType1 = nftType1;
    this.nftType2 = nftType2;
    this.metadataUrl = metadataUrl;
    this.createTime = createTime;
    this.owner = owner;
  }
}

export interface Ownership {
  id: string;
  user: User;
  nft: NFT;
  quantity: string;
}

export interface NFTAttribute {
  trait_type: string;
  value: string;
}

// NFT detail data
export interface NFTDetail {
  id: string;
  animation_url: string;
  attributes: Array<NFTAttribute>;
  description: string;
  external_url: string;
  gameType?: string;
  image: string;
  name: string;
  nftType1: string;
  nftType2: string;
}

// NFT id to image map
export const NFTImgMap = new Map([
  ['10001', 'https://raw.githubusercontent.com/neco-fun/neco-fun-nft-repo/main/neco-fishing/fishing-rod/1.bamboo-fishing-rod.png'],
  ['10002', 'https://raw.githubusercontent.com/neco-fun/neco-fun-nft-repo/main/neco-fishing/fishing-rod/2.wooden-fishing-rod.png'],
  ['10003', 'https://raw.githubusercontent.com/neco-fun/neco-fun-nft-repo/main/neco-fishing/fishing-rod/3.fiberglass-fishing-rod.png'],
  ['10004', 'https://raw.githubusercontent.com/neco-fun/neco-fun-nft-repo/main/neco-fishing/fishing-rod/4.carbon-fiber-fishing-rod.png'],
  ['10005', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fishing-rod/5.silver-fishing-rod.png?raw=true'],
  ['10006', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fishing-rod/6.gold-fishing-rod.png?raw=true'],
  ['10007', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fishing-rod/7.platinum-fishing-rod.png?raw=true'],
  ['11001', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish-trap/1.plastic-fish-trap.png?raw=true'],
  ['11002', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish-trap/2.bamboo-fish-trap.png?raw=true'],
  ['11003', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish-trap/3.nylon-fish-trap.png?raw=true'],
  ['11004', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish-trap/4.wire-fish-trap.png?raw=true'],
  ['11005', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish-trap/5.silver-fish-trap.png?raw=true'],
  ['11006', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish-trap/6.golden-fish-trap.png?raw=true'],
  ['11007', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish-trap/7.platinum-fish-trap.png?raw=true'],
  ['12001', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/treasure-chest/1.wooden-chest.png?raw=true'],
  ['12002', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/treasure-chest/2.silver-chest.png?raw=true'],
  ['12003', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/treasure-chest/3.golden-chest.png?raw=true'],
  ['13001', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/vegetable/1.catmint.png?raw=true'],
  ['13002', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/vegetable/2.watermelon.png?raw=true'],
  ['13003', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/vegetable/3.wheat.png?raw=true'],
  ['13004', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/vegetable/4.red-mushroom.png?raw=true'],
  ['13005', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/vegetable/5.leek.png?raw=true'],
  ['13006', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/vegetable/6.dandelion.png?raw=true'],
  ['14001', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/1.shrimp.png?raw=true'],
  ['14002', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/2.crab.png?raw=true'],
  ['15001', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/3.blowfish.png?raw=true'],
  ['15002', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/4.giant-salamander.png?raw=true'],
  ['15003', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/5.northern-pike.png?raw=true'],
  ['15004', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/6.crocodile.png?raw=true'],
  ['15005', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/7.white-fish.png?raw=true'],
  ['15006', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/8.opsariichthys-bidens.png?raw=true'],
  ['15007', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/9.tilapia.png?raw=true'],
  ['15008', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/10.grass-carp.png?raw=true'],
  ['15009', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/11.bighead-carp.png?raw=true'],
  ['15010', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/12.perch.png?raw=true'],
  ['15011', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/13.snakehead.png?raw=true'],
  ['15012', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/14.crucian-carp.png?raw=true'],
  ['15013', 'https://raw.githubusercontent.com/neco-fun/neco-fun-nft-repo/main/neco-fishing/fish/15.clam.png'],
  ['15014', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/16.eel.png?raw=true'],
  ['15015', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/17.snail-carp.png?raw=true'],
  ['15016', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/18.catfish.png?raw=true'],
  ['16001', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/19.hairtail.png?raw=true'],
  ['16002', 'https://raw.githubusercontent.com/neco-fun/neco-fun-nft-repo/main/neco-fishing/fish/20.sea-urchins.png'],
  ['16003', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/21.octopus.png?raw=true'],
  ['16004', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/22.shark.png?raw=true'],
  ['16005', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/23.pacific-saury.png?raw=true'],
  ['16006', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/24.sea-turtle.png?raw=true'],
  ['16007', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/25.sardine.png?raw=true'],
  ['16008', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/26.oyster.png?raw=true'],
  ['16009', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/27.pomfret.png?raw=true'],
  ['16010', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/28.grouper.png?raw=true'],
  ['16011', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/29.seahorse.png?raw=true'],
  ['16012', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/30.sea-cucumber.png?raw=true'],
  ['16013', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/31.unagi.png?raw=true'],
  ['16014', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/32.flatfish.png?raw=true'],
  ['16015', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/33.salmon.png?raw=true'],
  ['16016', 'https://github.com/neco-fun/neco-fun-nft-repo/blob/main/neco-fishing/fish/34.kinmedai.png?raw=true'],
]);

export function getNftImgById(nftId: string) {
  return NFTImgMap.get(nftId);
}

export interface NFTData {
  quantity?: string;
  price?: string;
  dark?: boolean; // library dark
}
