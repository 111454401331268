import axios from 'axios';
import { NFTDetail } from '@/configs/nft';

function getIpfsUrl(url: string) {
  let reqUrl = url;
  if (!url.startsWith('http')) {
    reqUrl = `https://mygateway.mypinata.cloud/${url}`;
  }
  return reqUrl;
}

export function getNftDetail(url: string): Promise<NFTDetail> {
  return axios.get(getIpfsUrl(url)).then((res) => res.data);
}

export function test() {
  // todo
}
