export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT Detail"])},
        "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Price"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BUY"])},
        "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SELL"])},
        "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSFER"])},
        "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seller"])},
        "properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Properties"])},
        "totalSupply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total supply"])},
        "tokenAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Address"])},
        "metadataLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata link"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "inputAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input a BSC address"])},
        "inputAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input transfer amount"])},
        "amountValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount should greater than 0"])}
      },
      "zh": {
        "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT详情"])},
        "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总价"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
        "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买"])},
        "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["售卖"])},
        "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转送"])},
        "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卖方"])},
        "properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["属性"])},
        "totalSupply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总量"])},
        "tokenAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token 地址"])},
        "metadataLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata 链接"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
        "inputAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入对方的地址"])},
        "inputAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入转送数量"])},
        "amountValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入大于0的数字"])}
      }
    }
  })
}
